import React from "react";
import "./About.css";
import JennyLu from "../Assets/AboutMe/JennyLu.png";
import Resume from "../Assets/AboutMe/CVJennyLu.pdf";
import Footer from '../Pages/Footer/Footer'
import Jenny2 from "../Assets/AboutMe/Me2.png"

export default function About() {
  // Method to open the PDF file
  const openPDF = () => {
    window.open(Resume, "_blank");
  };

  return (
    <div>
      <div className="container-about">
        <div className="containerabout abouttext">
          <h1 className="hej">Hej! Who Am I?</h1>
          <p className="about-me-text">
            I'm Jenny, a Swedish design engineer who believes in addressing
            challenges through intuitive and purposeful design. My motivation
            lies in the creation of products, experiences, and aesthetics that
            not only enhance individual lives but also contribute positively to
            our communities. <br />
            <br />
            My experience as both a developer, researcher, and designer helps me
            understand problems from various perspectives. I strongly believe
            that approaching creative deliverables with a UX focus can bridge
            those gaps and produce work that is efficient, pleasing, and
            ultimately enhances people’s experiences.
          </p>
          <br />
          <p className="about-me-text">
            In my free time, I enjoy many hobbies, and I always strive to learn
            something new. For example, I challenged myself to develop this
            portfolio from scratch, creating a Figma prototype and writing all
            the content and code myself, which has taught me a lot.
          </p>
        </div>
        <div className="imageJenny">
          <img src={Jenny2} alt="Jenny-Lu" />
        </div>
      </div>

      <div className="resume-container">
        <button className="View-Resume" onClick={openPDF}>
          View Resume
        </button>
      </div>
      <div>
      </div>
      <Footer/>
    </div>
  );
}
