import React, { useState } from 'react';
import './CarouselUpdated.css'; // Import CSS for styling
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'; // Import React Icons for arrows

import landing from '../Assets/Essiq/Brainstorming/landing.png';
import contact from '../Assets/Essiq/Brainstorming/contact.png';
import cases from '../Assets/Essiq/Brainstorming/cases.png';
import prototype1 from '../Assets/Essiq/Prototypes/prototype1.png';
import prototype2 from '../Assets/Essiq/Prototypes/prototype2.png';

const imageNames = ['Landing Page', 'Contact Page', 'Cases Page'];
const prototypeNames = ['Display of Projects', ' Contact Page'];

const Carousel = ({ type }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let images, names, containerClass;

  if (type === 'images') {
    images = [landing, contact, cases];
    names = imageNames;
    containerClass = 'image-carousel'; // Apply image carousel class
  } else if (type === 'prototypes') {
    images = [prototype1, prototype2];
    names = prototypeNames;
    containerClass = 'prototype-carousel'; // Apply prototype carousel class
  }

  const goToPrevSlide = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  return (
    <div className={`carousel-container ${containerClass}`}>
      <div className="arrows left-arrow" onClick={goToPrevSlide}>
        <BsChevronLeft />
      </div>
      <div className="carousel-content">
        <div className="image-name">{names[currentIndex]}</div>
        <div className="carousel-slide">
          <img
            src={images[currentIndex]}
            alt={`carousel slide ${currentIndex}`}
            className="active"
          />
        </div>
      </div>
      <div className="arrows right-arrow" onClick={goToNextSlide}>
        <BsChevronRight />
      </div>
    </div>
  );
};

export default Carousel;
