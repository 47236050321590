import React from "react";
import "./AIChatbot.css";
import AIChatbotIMG from "../../Assets/AIChatbot/AIChatbot.png";
import { BotSparkleFilled } from '@fluentui/react-icons';

function AIChatbot() {
  return (
    <div className="project-chatbot">
      <div className="AIChatbot-text">
          <h1 className="chatbot-title">CANEA Chat</h1>
          {/* <BotSparkleFilled /> */}
        <h4 className="chatbot-small-text">
          The development of an AI Chatbot within the business system CANEA ONE assisting users
          with complex tasks.
        </h4>
        <a href="/AIChatbot" className="Readmore">
          Read More
        </a>
      </div>
      <div className="chatbotimage-container">
        <img src={AIChatbotIMG} alt="AIChatbot" className="AIChatbot-img" />
      </div>
    </div>
  );
}

export default AIChatbot;

