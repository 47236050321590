import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import "./App.css"
import ProjectEssiq from './Pages/ProjectEssiq';
import ProjectFilmFriends from './Pages/ProjectFilmFriends';
import NavBar from './Navbar/Navbar';
import ProjectCanea from './Pages/AIChatbot/AIChatbotContent';


function App() {
  return (
    <>
    <NavBar/>
    <div className='container-app'>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/About" element={<About/>}/>
        <Route path="/FilmFriends" element={<ProjectFilmFriends/>}/>
        <Route path="/Essiq" element={<ProjectEssiq/>}/>
        <Route path="/AIChatbot" element={<ProjectCanea/>}/>
      </Routes>
    </div></>
  );
}

export default App;
