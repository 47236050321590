import React, { useState, useEffect, useRef } from "react";
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import "./Navbar.css";

export default function NavBar() {
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollY = useRef(window.scrollY);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY.current) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
      lastScrollY.current = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getNavbarClass = () => {
    switch (location.pathname) {
      case "/":
        return "home-navbar";
      case "/About":
        return "about-navbar";
      default:
        return "default-navbar";
      case "/FilmFriends":
        return "FilmFriends-navbar";
      case "/essiq":
        return "Essiq-navbar";
        case "/AIChatbot":
            return "Canea-navbar";
    }
  };

  return (
    <nav className={`nav ${showNavbar ? "show" : "hide"} ${getNavbarClass()}`}>
      <Link to="/" className="site-title">
        Jenny Lu
      </Link>
      <ul>
        <CustomLink to="/About">About me</CustomLink>
        <li>
          <a href="mailto:Jeennyluu@gmail.com?">Contact</a>
        </li>
      </ul>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to}>{children}</Link>
    </li>
  );
}
