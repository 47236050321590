import React from "react";
import "./FooterPage.css";
import Footer from "./Footer";

function FooterPage() {
  return (
    <div>
      <div className="Footer-page">
        <h1 className="footer-title">Let's Connect!</h1>
        <p className="footer-bodytext">
          I'm always eager to explore new opportunities and forge meaningful
          connections. Reach out, and let's have a chat ◡̈
        </p>
        <a href="mailto:Jeennyluu@gmail.com?" className="footer-email">
          Jeennyluu@gmail.com
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default FooterPage;
