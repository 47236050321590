import React, { useState, useEffect } from 'react';

const Typewriter = ({ texts }) => {
  const [displayText, setDisplayText] = useState('A ');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const typingSpeed = 100; // Adjust typing speed as needed
  const pauseBetweenPhrases = 1000; // Adjust pause between phrases

  useEffect(() => {
    const currentText = texts[currentIndex % texts.length];

    const type = () => {
      if (!isDeleting && displayText !== 'A ' + currentText) {
        setDisplayText(prevText => prevText + currentText[prevText.length - 2]);
      } else if (!isDeleting && displayText === 'A ' + currentText) {
        setTimeout(() => setIsDeleting(true), pauseBetweenPhrases);
      } else if (isDeleting && displayText !== 'A ') {
        setDisplayText(prevText => prevText.slice(0, -1));
      } else if (isDeleting && displayText === 'A ') {
        setIsDeleting(false);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }
    };

    const typingTimeout = setTimeout(type, typingSpeed);
    return () => clearTimeout(typingTimeout);
  }, [displayText, currentIndex, isDeleting, texts, typingSpeed, pauseBetweenPhrases]);

  return <div className="title animated-text">{displayText}</div>;
};

export default Typewriter;
