import React, { useState, useEffect, useRef } from "react";
import "./AIChatbotContent.css";

import Footer from "../Footer/Footer";
import { IoIosArrowUp } from "react-icons/io";
import CANEAChat from "../../Assets/AIChatbot/AIChatbot.png";
import Sprint from "../../Assets/AIChatbot/DesignSprint.png";
import Storyboard from "../../Assets/AIChatbot/Storyboard_Per.png";
import craxy8 from "../../Assets/AIChatbot/Crazy8_2.jpg";
import midfiwireframes from "../../Assets/AIChatbot/midfi.png";
import hifi1 from "../../Assets/AIChatbot/hifi1.png";
import hifi2 from "../../Assets/AIChatbot/hifi2.png";
import hifi3 from "../../Assets/AIChatbot/hifi3.png";
import hifi4 from "../../Assets/AIChatbot/hifi4.png";
import videoPath from "../../Assets/AIChatbot/CANEAChatgif.mp4";
import tooltips from "../../Assets/AIChatbot/Tooltips.png";

function ProjectCanea() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const findingRef = useRef(null);
  const findingRef2 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
      setShowScrollToTop(currentPosition > 0.5 * window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer3 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("intersecting!");
            entry.target.style.backgroundColor = "#b1cdea"; // Apply background color inline
            entry.target.classList.add("finding2Active");
            observer3.unobserve(entry.target); // Unobserve the element after it's visible
          }
        });
      },
      { threshold: 0.2 }
    );

    const observer4 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("intersecting!");
            entry.target.classList.add("keyActive");
          }
        });
      },
      { threshold: 0.3 }
    );

    const findingElement = findingRef.current;
    const findingElement2 = findingRef2.current;

    if (findingElement) {
      observer3.observe(findingElement);
      observer4.observe(findingElement2);
    }

    return () => {
      if (findingElement) {
        observer3.unobserve(findingElement);
        observer4.unobserve(findingElement2);
      }
    };
  }, []);

  useEffect(() => {
    const hiddenElements = document.querySelectorAll(".hiddenElement");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showElement");
          observer.unobserve(entry.target); // Unobserve the element after it's visible
        }
      });
    });

    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <section className="container-Canea">
        <h1 className="Canea-title">CANEA Chat</h1>
        <h2 className="Canea-subtitle">
          An AI chatbot within the business system CANEA ONE
        </h2>
      </section>
      <div className="hiddenElement">
        <div className="container-image-canea-prototype ">
          <img
            src={CANEAChat}
            className="image-Canea-case-study-1"
            alt="Canea-case-study"
          />
        </div>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>Overview</h2>
        </div>
        <p className="margin">
          CANEA provides management consulting, IT solutions, and training.
          Their business application, CANEA ONE, digitizes organizational
          workflows to ensure structured and goal-oriented work. CANEA ONE
          includes five modules—Strategy, Project, Document, Workflow, and
          Process—enabling organizations to set and track goals, manage
          projects, handle documents, automate workflows, and visualize business
          processes. This integrated system offers a cohesive overview of
          activities, ensuring version control and synchronization across
          documents and projects. <br />
          <br />
          This project explores how an AI chatbot could assist users with tasks
          in the system to improve the overall user experience.
        </p>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>The Context</h2>
        </div>
        <p className="margin">
          CANEA ONE is a large and complex application with users of varying
          levels and purposes. Issues may arise in different sections of the
          program. Users with infrequent usage and no administrative roles
          typically focus on viewing documents and statuses within the document
          modules. These users commonly encounter navigational challenges and
          difficulties locating specific documents and information.. <br />
          <br /> Administrators, tasked with configuring workflows or documents,
          face more intricate problems, such as designing processes using the
          "process designer" feature. Configuring can be challenging because
          each setup is unique, and there are many ways to do it. Simply relying
          on the manual isn't always enough, and asking colleagues for help
          might result in different answers. Users can refer to a manual or to
          others when faced with challenges however many experience that the
          information in the manual only covers what tools do but not how they
          should be used.
        </p>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>The Process</h2>
        </div>
        <p className="margin">
          The project was planned using the design sprint from google which
          consists of 6 phases: understand, define, sketch, decide, protote and
          finally validate.
        </p>
        <div className="container-image">
          <img alt="theprocess" className="processimage" src={Sprint} />
        </div>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>Understand</h2>
        </div>
        <p className="margin">
          To understand the problem a literature review was conducted where
          existing research was reviewed. This was followed by questionnaires
          and interviews. Key findings were extracted through affinity
          diagramming.
        </p>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div>
          {" "}
          <h3>Quantitative Questionnaires</h3>
          <p className="margin-small">
            To gather qualitative data on the user pattern within CANEA ONE a
            questionnaire was sent out asking what users needed the most
            assistance with in the system. The questionnaire was sent out to
            employees and customers of the company and recieved 51 responses.
            The following key insights were established.
          </p>
          <div className="margin-small">
            <div className="questionnaire">
              <li> Most users use the system daily</li>
              <li>
                The majority of users are of proficiency level intermediate or
                advanced
              </li>
              <li>
                Many users have experienced problems within the system with
                tasks such as:
              </li>
            </div>
            <div className="insights-survey">
              <div className="key-finding-survey">
                <p className="bold">Configuration in modules</p>
              </div>

              <div className="key-finding-survey">
                <p className="bold">Navigation in the system</p>
              </div>
              <div className="key-finding-survey">
                <p className="bold">Locating information</p>
              </div>
            </div>
          </div>
          <h3>Quantitative Questionnaires</h3>
          <p className="margin">
            For this project the interview type semi-structured interview was
            selected. A total of six participants were selected from the
            questionnaires considering that they need to have preceding
            experience with CANEA ONE and three participants of intermediate and
            three participants of advanced skill level were selected because no
            beginner users were available. To synthesize the data affinity
            diagram was used.
          </p>
        </div>
      </div>
      <div className="section-findings" ref={findingRef}>
        <div className="section-title">
          <p className="section-title">Key Insights & Findings</p>
        </div>
        <div className="container-insights">
          <div className="key-insight">
            <p className="bold">Navigation Difficulites</p>
            <p>
              Users had difficulties navigating especially when configuring
              since settings are scattered across the application, which the
              user may not realize until they have started with a task. Leaving
              the task means they lose all the progress made and will have to
              restart.
            </p>
          </div>
          <div className="key-insight">
            <p className="bold">Lack of feedback</p>
            <p>
              The lack of feedback during configurations causes significant
              issues since it permits users to assign any values, even if they
              might not be functional, potentially leading to crashes when
              attempting to create something.
            </p>
          </div>
          <div className="key-insight">
            <p className="bold">Existing support</p>
            <p>
              Most users prefer their colleagues as their primary source of
              assistance while using CANEA ONE, rather than the manual. Many
              voiced concerns about the manual's inadequacy in explaining the
              functions comprehensively, noting inconsistencies in detail across
              its pages. Participants highlighted the manual's utility primarily
              for looking up tags, which can be challenging to memorize.
            </p>
          </div>
          <div className="key-insight">
            <p className="bold">User group</p>
            <p>
              After the interviews, we realized that there are not many issues
              with basic functions, such as viewing documents, which are the
              functions most commonly used by regular users. The majority of
              problems arise for administrative users who handle more complex
              functions, such as configuring documents and managing cases. If
              the administrator sets up the application effectively, it will
              become easier to use for end users. Therefore, this is the area
              where the most assistance is needed from the AI assistant/chatbot.
            </p>
          </div>
        </div>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>Define</h2>
        </div>
        <p className="margin">
          CANEA ONE is a large and complex application with users of varying
          levels and purposes. Issues may arise in different sections of the
          program. Users with infrequent usage and no administrative roles
          typically focus on viewing documents and statuses within the document
          modules. These users commonly encounter navigational challenges and
          difficulties locating specific documents and information.
          <br />
          <br />
          Administrators, tasked with configuring workflows or documents, face
          more intricate problems, such as designing processes using the
          "process designer" feature. Configuring can be challenging because
          each setup is unique, and there are many ways to do it. Simply relying
          on the manual isn’t always enough, and asking colleagues for help
          might result in different answers. Users can refer to a manual or to
          others when faced with challenges however many experience that the
          information in the manual only covers what tools do but not how they
          should be used.
        </p>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>Sketch</h2>
        </div>
        <div className="margin">
          <h5>Low Fidelity Wireframes</h5>
          <p className="margin-small">
            Concepts were explored visually with low fidelity wireframes using
            the ideation method crazy eights. The different concept touched on
            aspects that affects the interaction between user and chatbot in the
            system. The sketch below showcases four of 16 sketches that were
            generated from the sketching session.
          </p>
        </div>

        <div className="image-container">
          <img src={craxy8} className="image-crazy-eights" alt="crazy-eights" />
        </div>
        <div className="margin-small">
          <p> The concepts were further developed using Figma </p>
        </div>
        <div className="image-container-midfi">
          <img
            src={midfiwireframes}
            className="image-midfi"
            alt="crazy-eights"
          />
        </div>
      </div>
      <div className="hiddenElement">
        <div className="container-images-hifi-prototypes">
          <div className="center-text highfi">
            <h5 className="white-font">Mid Fidelity Wireframes</h5>
          </div>
          <div>
            <p className="white-font margin-hifi-text">
              Two concept were selected after careful consideration with the
              stakeholders and were made further developed with features derived
              from the co-design workshop. These mid fidelity wireframes were
              later evaluated using heuristic evaluations with three usability
              experts.
            </p>
          </div>
          <div className="hifi-images">
            <div className="row hifirow">
              <img
                src={hifi1}
                alt="high fidelity prototype 1"
                className="image-hifi-prototype"
              />
              <img
                src={hifi2}
                alt="high fidelity prototype 1"
                className="image-hifi-prototype"
              />
            </div>
            <div className="row hifirow">
              <img
                src={hifi3}
                alt="high fidelity prototype 1"
                className="image-hifi-prototype"
              />
              <img
                src={hifi4}
                alt="high fidelity prototype 1"
                className="image-hifi-prototype"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="space" />
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>Prototype</h2>
        </div>
        <p className="margin-small">
          The goal of the prototyping phase was to create a functional prototype
          that is interactive enough to be user tested and evaluated.
        </p>
        <div className="margin-small">
          <h5>Development</h5>
        </div>
        <p>
          The AI chatbot was developed using React Typescript as frontend and
          Python and Flask as backend. MongoDB was used as the database and
          OpenAI API for the AI. Git was used for source code management.
        </p>
        <video
          src={videoPath}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: "auto",
            marginTop: "5%",
            marginBottom: "2%",
            borderRadius: "5px",
          }}
        />
        <p className="margin-small">
          The smaller interface of the chatbot could be opened through a button
          on the bottom left corner. This means that users are able to access
          the chatbot while conducting tasks in the system. Additionally, the
          window can made bigger through pressing the expand icon.
        </p>
        <div className="space" />
        <div className="margin">
          <div className="center-text">
            <h2>Validate</h2>
          </div>
          <p className="margin-small">
            The validation phase of the project was aimed at gathering feedback
            from users who interact with the prototype. The feedback will then
            be used to re-iterate the concept to make improvements.
          </p>
          <div className="margin-small">
            <h5>User-tests</h5>
          </div>
          <p>
            In order to evaluate the prototype a total of six user tests were
            conducted with employees of CANEA who are frequent users of the
            system. During the user-test participants were asked to perform
            three tasks. The tasks progressively became more difficult in order
            to test how the chatbot works for different levels of
            difficulty/user groups. Additionally, in order to observe the
            intuitiveness of the design Half of the participants got
            instructions of how the chatbot works and half of them got no
            instructions.
          </p>
          <div className="margin-small">
            <li>Make an absence report between the dates 9-19th of April.</li>
            <li>
              Turn on subscriptions to email and subscribe to document view
              "instructions".
            </li>
            <li>
              Create a configuration of a phase, a case type and a case with the
              following instructions:
              <br />
              <div className="margin-extrasmall">
                <em>
                  “There was an accident outside the CANEA office yesterday, a
                  car collided with a bicycle and we want you to create a new
                  case with the case type "Accident" which contains a phase
                  called “Accident Registration”. The phase must contain a
                  description of the accident and the date.”
                </em>
              </div>
            </li>
          </div>
        </div>
      </div>
      <div className="section-findings" ref={findingRef2}>
        <div className="section-title">
          <p className="section-title">User-test Findings</p>
        </div>
        <p className="margin-small special-margin">
          The users were mostly positive toward the chatbot prototype. The
          interface was considered minimalist and well integrated into the
          application.
        </p>
        <div className="container-insights">
          <div className="key-insight">
            <p className="bold">Icons</p>
            <p>
              Several participants noted that the icon chosen for initiating a
              new chat was not clearly indicating its function. One participant
              mentioned that the icon is similar to the document edit icon used
              elsewhere in the application and could therefore be misunderstood
              as being connected to documents.
              <br />
              <br />
              <em>
                "I think it’s a bit unclear that the icon for the chatbot is a
                document icon; I believe it’s used elsewhere..." - Participant 2
              </em>
            </p>
          </div>
          <div className="key-insight">
            <p className="bold">Window Size</p>
            <p>
              Since users had to use the smaller version of the chatbot while
              completing tasks, some commented that it was too small or
              expressed a desire to adjust the size of the chatbot window. While
              some thought the smaller chatbot was a good size.
              <br />
              <br />
              <em>
                "I think the small one works fine too. It's mostly the UI thing
                that you can adjust how big it is yourself." - Participant 1
              </em>
            </p>
          </div>
          <div className="key-insight">
            <p className="bold">Small Chatbot</p>
            <p>
              The minimized version of the chat was considered an important
              addition to enable users to view instructions at the same time as
              completing tasks in the interface.
              <br />
              <br />
              <em>
                "It would have been really annoying if one had to go back and
                forth all the time." - Participant 6
              </em>
            </p>
          </div>
          <div className="key-insight">
            <p className="bold">Intrusiveness</p>
            <p>
              One participant compared the chatbot to those commonly used on
              e-commerce web- sites. Many e-commerce platforms feature chatbots
              that automatically pop up when users visit the webpage, initiating
              a chat without their consent. Such intrusive be- havior can lead
              to irritation and frustration in the user experience. The
              participant expressed a preference for a more discreet interface
              that only appears when the user chooses to engage with it.
              <br />
              <br />
              <em>
                "I think that’s more annoying than when it’s discreet and pops
                up when you want it to." - Participant 2
              </em>
            </p>
          </div>
        </div>
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="margin">
          <div className="center-text">
            <h2>Final Product</h2>
          </div>
        </div>
        <p className="margin-small">
          After analysing and summarising the data collected from the literature
          study, interviews and user tests general guidelines for designing AI
          Chatbots within business applications were established. The guidelines
          are summerised below:
        </p>
        <div className="container-guideline">
          <div className="guideline">
            <h6>Tool-tips</h6>
            <p>Give explanations to functions that may be new to users</p>
          </div>
          <div className="guideline">
            <h6>AI-Personality</h6>
            <p>
              Make it clear to the user that the chatbot is an AI and do not
              pretend that it is human.
            </p>
          </div>
          <div className="guideline">
            <h6>Minimalist Design</h6>
            <p>
              Keep the design as minimal as possible to decrease clutter in the
              application.
            </p>
          </div>
          <div className="guideline">
            <h6>Size</h6>
            <p>
              Do not make the chatbot smaller than 350px * 400px to ensure read-
              ability.
            </p>
          </div>
          <div className="guideline">
            <h6>Small-interface</h6>
            <p>
              There should be a small version of the chatbot that is accessible
              in all pages of the application.
            </p>
          </div>
          <div className="guideline">
            <h6>Perception of Contro</h6>
            <p>
              The chatbot should only write to the user after the user has
              contacted it first.
            </p>
          </div>
          <div className="guideline">
            <h6>Consistency </h6>
            <p>
              The chatbot design should be consistent with the existing
              application in colors, fonts and icons.
            </p>
          </div>
          <div className="guideline">
            <h6>Complement Existing Solutions</h6>
            <p>
              The aim of the chatbot is to compliment existing solutions such as
              customer support and manual not to replace these.
            </p>
          </div>
          <div className="guideline">
            <h6>Location of Chatbo</h6>
            <p>
              The chatbot should be easily accessible trough either the main
              menu or at the bottom right of the screen.
            </p>
          </div>
          <div className="guideline">
            <h6>Chatbot Response</h6>
            <p>
              Responses should be offfered in bullet paoints when steps are
              discribed.
            </p>
          </div>
          <div className="guideline">
            <h6>Anticipation</h6>
            <p>
              The status of the chatbot be visualised to indicate it’s status to
              the user.
            </p>
          </div>
          <div className="guideline">
            <h6>Accessible Design</h6>
            <p>The design should follow the WCAG to ensure accessibility.</p>
          </div>
          <div className="guideline">
            <h6>Differentiating Chatbot and User Messages</h6>
            <p>
              The design should clearly indicate wich messages are from the user
              and which are from the Chatbot.
            </p>
          </div>
        </div>
        <div className="space" />
        <div className="margin-small">
          <p className="section-title">Final Iteration</p>
        </div>
        <p>
          These guidelines were used to make improvements to the prototype. The
          imporvements included:
        </p>
        <div className="margin-extrasmall">
          <li className="margin-extrasmall">
            Switching out the new chat icon. The previous icon was associated
            with documents and caused confusion and was replaced with the
            "compose" icon from fluent icons.
          </li>
          <li className="margin-extrasmall">
            Tool-tips were added to all functions. The tool-tips will aid in
            making new users feel more confident in exploring the new functions
          </li>
          <li className="margin-extrasmall">
            The font-size for all bodytext was increased from 12px to 16px since
            it is more accessible and readable. 55
          </li>
        </div>
        <img
          className="final-prototype-img"
          src={tooltips}
          alt="prototype improvement"
        />
      </div>
      <div className="container-Canea-Content hiddenElement">
        <div className="center-text">
          <h2>Reflections</h2>
        </div>
        <div className="margin">
          <div>
            <h6>AI Trust</h6>
            <p className="margin-extrasmall">
              This project mainly focused on designing the grahipcal interface
              of an AI chatbot within a business application and what
              implication it has on the user experience the quality of the
              respons of the AI Chatbot without a doubt had an impact on
              participant responses. For a more accurate study I would suggest
              working on improving the AI response and how those look like. For
              example how would the response look like if the AI tried to help
              the user navigate to a certain function?
            </p>
          </div>
          <div className="margin-extrasmall">
            <h6>Involving Stakeholders</h6>
            <p className="margin-extrasmall">
              During the sketching phase we decided to hold a co-design workshop
              where we involved CANEA into the ideation phase. This really
              helped us align with their vision and gave us an good estimation
              on their expectations for the project. Finally, it their input
              gave us inspirations for feautures that otherwise might not have
              been considered.
            </p>
          </div>
        </div>
      </div>

      {showScrollToTop && (
        <button className="scroll-to-top" onClick={handleScrollTop}>
          <IoIosArrowUp className="scroll-arrow" />
        </button>
      )}
      <Footer />
    </div>
  );
}

export default ProjectCanea;
