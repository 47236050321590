import React from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import './Email.css'
const EmailIcon = () => {
  return (
    <div className='email-container'>
      <a href="mailto:Jeennyluu@gmail.com?">
        <MdOutlineEmail className='Email-icon' />
      </a>
    </div>
  );
};

export default EmailIcon;