import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import EssiqDisplay from "../Assets/Essiq/EssiqDisplay.png";
import FilmFriendsDisplay from "../Assets/FilmFriends/FilmFriendsDisplay.png";
import ProfilePicture from "../Assets/AboutMe/profilepicture.png";
import Typewriter from "./Typewriter";
import AIChatbot from "./AIChatbot/AIChatbot";
import ProjectTemplate from "./Projects/ProjectTemplate";
import FooterPage from "./Footer/FooterPage";

export default function Home() {
  const phrases = ["UX/UI Designer", "Fullstack Developer"];
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const additionalProjectsRef = useRef(null);
  const chatbotSectionRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
      if (currentPosition > 0.5 * window.innerHeight) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          } else {
            entry.target.classList.remove("active");
          }
        });
      },
      { threshold: 0.25 }
    );

    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("chatbot-active");
          } else {
            entry.target.classList.remove("chatbot-active");
          }
        });
      },
      { threshold: 0.5 }
    );

    const observer3 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("intersecting!");
            entry.target.classList.add("footerActive");
          } else {
            entry.target.classList.remove("footerActive");
          }
        });
      },
      { threshold: 0.5 }
    );

    const additionalProjectsElement = additionalProjectsRef.current;
    const chatbotSectionElement = chatbotSectionRef.current;
    const footerElement = footerRef.current;

    if (additionalProjectsElement) {
      observer.observe(additionalProjectsElement);
    }
    if (chatbotSectionElement) {
      observer2.observe(chatbotSectionElement);
    }
    if (footerElement) {
      observer3.observe(footerElement);
    }

    return () => {
      if (additionalProjectsElement) {
        observer.unobserve(additionalProjectsElement);
      }
      if (chatbotSectionElement) {
        observer2.unobserve(chatbotSectionElement);
      }
      if (footerElement) {
        observer3.unobserve(footerElement);
      }
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScrollDown = () => {
    window.scrollTo({
      top: scrollPosition + window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="container-Home">
        <div className="content">
          <div className="hero-section">
            <div className="hero-text">
              <h1 className="my-name animated-text">Hi! I'm Jenny</h1>
              <Typewriter className="title animated-text" texts={phrases} />
              <h1 className="description animated-text">
                I'm passionate about creating and developing user-friendly
                experiences!
              </h1>
            </div>
            <div>
              <img className="profilepic" src={ProfilePicture} alt="Jenny" />
            </div>
          </div>
        </div>
        <IoIosArrowDown className="arrow" onClick={handleScrollDown} />
      </div>
      <div ref={chatbotSectionRef} className="chatbotsection">
        <AIChatbot />
      </div>
      <div ref={additionalProjectsRef} className="AdditionalProjects">
        <ProjectTemplate
          projectRef={"/essiq"}
          image={EssiqDisplay}
          title="Specifications for a webpage"
          alttext="essiq prototype"
          subtitle="An AI Chatbot within the business system CANEA ONE assisting users with complex tasks."
        />
        <ProjectTemplate
          image={FilmFriendsDisplay}
          title="FilmFriends App"
          projectRef={"/FilmFriends"}
          alttext="FilmFriends prototypes"
          subtitle="An interactive movie swiping app created for friendgroups of any size"
        />
      </div>
      <div ref={footerRef} className="footer-section">
        <FooterPage />
      </div>
      {showScrollToTop && (
        <button className="scroll-to-top" onClick={handleScrollTop}>
          <IoIosArrowUp className="scroll-arrow" />
        </button>
      )}
    </div>
  );
}
