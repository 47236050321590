import React from "react";
import "./ProjectFilmFriends.css";
import FilmFriendsFirst from "../Assets/FilmFriends/FilmFriendsFirst.png";
import Matched from "../Assets/Competitors/matched.png";
import MovieSwipe from "../Assets/Competitors/movieswipe.png";
import Binger from "../Assets/Competitors/binger.png";
import prototype1 from "../Assets/FilmFriends/Prototypes/mobile-prototype1.png";
import prototype2 from "../Assets/FilmFriends/Prototypes/mobile-prototype2.png";
import prototype3 from "../Assets/FilmFriends/Prototypes/mobile-prototype3.png";
import prototype4 from "../Assets/FilmFriends/Prototypes/mobile-prototype4.png";
import prototype5 from "../Assets/FilmFriends/Prototypes/mobile-prototype5.png";
import prototype6 from "../Assets/FilmFriends/Prototypes/mobile-prototype6.png";
import FFdiscover from "../Assets/FilmFriends/FinalDesign/FFdiscover.png";
import FFfirstpage from "../Assets/FilmFriends/FinalDesign/FFfirstpage.png";
import FFvote from "../Assets/FilmFriends/FinalDesign/FFvote.png";
import FFwatchlist from "../Assets/FilmFriends/FinalDesign/FFwatchlist.png";
import FFswipe from "../Assets/FilmFriends/FinalDesign/FFswipe.png";
import reactNative from "../Assets/FilmFriends/Development/react-native-1.png";
import git from "../Assets/FilmFriends/Development/git.png";
import github from "../Assets/FilmFriends/Development/GitHub-logo.png";
import expoGo from "../Assets/FilmFriends/Development/expo.png";
import Footer from "../Pages/Footer/Footer";
import Appstore from "../Assets/FilmFriends/Appstore.png";
import { IoIosArrowUp } from "react-icons/io";
import { useState, useEffect, useRef } from "react";

function ProjectFilmFriends() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const hiddenElements = document.querySelectorAll(".hiddenElement");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.1) { // Trigger when 50% of the element is visible
          entry.target.classList.add("showElement");
          console.log("intersecting!");
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: [0.1] });
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
      if (currentPosition > 0.5 * window.innerHeight) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div>
        <section className="container-filmfriends">
          <h1 className="FilmFriends-title">FilmFriends</h1>
          <h2 className="FilmFriends-subtitle">
            For Movie Nights That Never Ends
          </h2>
        </section>
      </div>

      <div className="container-introduction hiddenElement">
        <p className="container-introduction-text">
          In the realm of collaborative movie selection, our "Film Friends"
          project embarked on a mission to revolutionize the way groups choose
          movies for collective viewing. We aimed to simplify the
          decision-making process, making it enjoyable and user-friendly, while
          addressing common challenges like differing preferences, limited
          information, and time-consuming selections.
        </p>
      </div>
      <div className="container-image-filmfriends hiddenElement">
        <img
          className="image-filmfriendsfirst"
          src={FilmFriendsFirst}
          alt="filmfriends"
        />
      </div>
      <div className="section-container hiddenElement">
        <h1 className="section-title">The Problem</h1>
        <p className="section-bodytext">
          Many social gatherings, be it with friends, family, or colleagues,
          frequently encounter challenges when attempting to select a movie for
          collective viewing. These difficulties often manifest as
          disagreements, prolonged decision-making processes, and a lack of
          consensus, leading to frustration and sub optimal movie-watching
          experiences. The problem arises from a variety of factors, including
          differing preferences, limited information, and an absence of
          effective decision-making mechanisms, ultimately impeding the smooth
          selection of a movie that satisfies the diverse tastes and
          expectations of the group. Addressing these challenges and enhancing
          the decision-making process is essential to improve the overall
          enjoyment and cohesion of such gatherings.
        </p>
      </div>
      <div className="section-container hiddenElement">
        <h1 className="section-title">The User</h1>
        <p className="section-bodytext">
          The target audience for our app encompasses anyone who enjoys watching
          movies together, but our primary focus will be on users aged 18-44.
          This age group has been identified as the most avid movie watchers,
          with approximately 30 percent of individuals in this demographic
          watching movies daily, as reported by Statista. Furthermore, the
          younger generation, aged 18-24, is the most active in using
          entertainment apps, representing 47 percent of the user base. This
          demographic presents an ideal opportunity for initial engagement with
          our app. Nevertheless, our overarching goal is to ensure accessibility
          for users of all age groups, even though our primary focus remains on
          younger individuals.
        </p>
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">Competitor Analysis</div>
        <p className="section-bodytext">
          After deciding on creating a collaborative app for choosing movies we
          started to look at existing solutions and have identified 3 main
          competitors: MovieSwipe, Binger and Matched. However, matched and
          Binger only supports usage for two people while in MovieSwipe the user
          can swipe by themselves or create a party. After analysing the apps we
          noticed how they were lacking in many aspects and that our vision is
          different from their solutions. Additionally, one thing these apps
          have in common is that they showcase movies that members of party have
          swiped right on but they never end up with just one movie which in
          many cases is the end-goal.
        </p>
        <div className="container-competitors">
          <img className="competitor" src={Matched} alt="Matched" />
          <img className="competitor" src={Binger} alt="Binger" />
          <img className="competitor" src={MovieSwipe} alt="MovieSwipe" />
        </div>
        <p className="competitor-text">
          {" "}
          Icons of the apps binger, matched and MovieSwipe
        </p>
      </div>
      <div className="section-container hiddenElement">
        <h1 className="section-title">Figma Prototypes</h1>
        <p className="section-bodytext">
          Figma was used to easily create different design options and making it
          easy to iterate the design without implementing it in the application.
        </p>
        <div className="container-figma-prototypes">
          <div className="container-figma-row">
            <img
              className="figma-prototype"
              src={prototype1}
              alt="prototype1"
            />
            <img
              className="figma-prototype"
              src={prototype2}
              alt="prototype2"
            />
            <img
              className="figma-prototype"
              src={prototype3}
              alt="prototype3"
            />
          </div>
          <div className="container-figma-row">
            <img
              className="figma-prototype"
              src={prototype4}
              alt="prototype4"
            />
            <img
              className="figma-prototype"
              src={prototype5}
              alt="prototype5"
            />
            <img
              className="figma-prototype"
              src={prototype6}
              alt="prototype6"
            />
          </div>
        </div>
        <h1 className="section-title">Development</h1>
        <p className="section-bodytext">
          The app was developed using the cross-platform framework React Native
          within Visual Studio Code. The development process involved four team
          members who worked simultaneously. To facilitate collaboration and
          version control, Git and GitHub were utilized. Additionally, Expo Go
          was employed during development to simplify the process of previewing
          and testing the apps on real devices, eliminating the need for complex
          setup procedures.
        </p>
        <div className="container-developent-tools">
          <img
            className="development-tool"
            src={reactNative}
            alt="reactNative"
          />
          <img className="development-tool" src={git} alt="Git" />
          <img className="development-tool" src={github} alt="Github" />
          <img className="development-tool" src={expoGo} alt="expo" />
        </div>
        <h1 className="section-title">User Testing</h1>
        <p className="section-bodytext">
          To evaluate the functional prototype, a focus group was conducted
          wherein participants engaged in informal discussions on a specific
          topic, offering qualitative insights into actions, beliefs, and
          attitudes. The focus group primarily tested the application's
          functionality. Participants used the app without instructions,
          providing feedback. Suggestions included saving movie lists, viewing
          trailers, and clearer instructions. Updates were made accordingly.
          Admins were reminded to wait for participants to join before
          proceeding. After receiving feedback, changes were made to the design.
        </p>
        <h1 className="section-title">Final Design</h1>
        <div className="final-design-introduction">
          <p className="section-bodytext-final-design-introduction">
            Introducing Filmfriends, the ultimate movie-community app!
          </p>
          <img
            className="Final-design-login"
            src={FFfirstpage}
            alt="filmfriends-login/signin"
          />
        </div>
        <div className="Final-design-discover">
          <img
            src={FFdiscover}
            className="Final-design-discover-image"
            alt="Final-design-discover"
          />
          <div className="column">
            <h1>Discover</h1>
            <p className="final-design-text">
              Discover trending and upcoming movies by exploring or through
              swiping.
            </p>
          </div>
        </div>
        {/* <div className="Final-design-swipe">
          <div className="FF-swipe">
            <h1>Swipe</h1>
            <img src={FFswipe} alt="FFswipe" />
          </div>
        </div> */}
        <div className="justify-content">
          <div className="Final-design-swipe">
            <div className="column">
              <h1>Swipe</h1>
              <p className="final-design-text">
                Swipe through endless of content filtering through your
                favourite genres!
              </p>
            </div>
            <img src={FFswipe} className="FF-swipe" alt="Final-design-swipe" />
          </div>
        </div>

        <div className="Final-design-watchlist">
          <img
            src={FFwatchlist}
            className="Final-design-watchlist-image"
            alt="Final-design-watchlist"
          />
          <div className="column">
            <h1>Create Watchlists</h1>
            <p className="final-design-text">
              Create watchlists with you friends and add movies!
            </p>
          </div>
        </div>
        <div className="justify-content">
          <div className="Final-design-vote">
            <div className="column">
              <h1>Vote</h1>
              <p className="final-design-text">
                Start voting sessions on your watchlists and let the people
                decide. Say goodbye to debates and disagreements.
              </p>
            </div>
            <img
              src={FFvote}
              className="Final-design-vote-image"
              alt="Final-design-watchlist"
            />
          </div>
        </div>
        <div className="appstore">
          <a href="https://apps.apple.com/in/app/filmfriends/id6475875278?platform=iphone">
            <img src={Appstore} alt="Appstore" />
          </a>
        </div>
        <div className="container-section-reflections">
          <h1 className="section-title">Reflection & Future Development</h1>
          <p className="section-bodytext-future-development">
            Going into this project, none of the team members had any experience
            with app development or React Native. What we did have was a great
            drive for problem-solving and a fantastic idea we wanted to realize.
            While we are very happy with what we have achieved, there are a lot
            of things we would have done differently if we were to do it again.
            For example, the structuring of the project in Visual Studio Code.
            Some functionality we wish to implement in the future includes:
          </p>

          <div className="development-list-container">
            <ul className="future-development-list">
              <li className="development-list-item">
                <strong style={{ fontFamily: "SF-Semibold" }}>Series</strong> -
                Expanding our content library to include TV series alongside
                movies.
              </li>
              <li className="development-list-item">
                <strong style={{ fontFamily: "SF-Semibold" }}>
                  Review System
                </strong>{" "}
                - Implementing a review system within the app will enable users
                to share and read opinions on movies.
              </li>
              <li className="development-list-item">
                <strong style={{ fontFamily: "SF-Semibold" }}>
                  Improved Algorithms
                </strong>{" "}
                - To provide a more tailored experience, we aim to develop a
                feature that curates movie suggestions based on users’
                previously watched films.
              </li>
              <li className="development-list-item">
                <strong style={{ fontFamily: "SF-Semibold" }}>
                  Daily Engagement Features
                </strong>{" "}
                - Interactive features, such as movie themed quizzes inspired by
                the popular word puzzle game Wordle.
              </li>
              <li className="development-list-item">
                <strong style={{ fontFamily: "SF-Semibold" }}>Tutorial</strong>{" "}
                - A brief tutorial that would appear the first time a user signs
                in to their account.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      {showScrollToTop && (
        <button className="scroll-to-top" onClick={handleScrollTop}>
          <IoIosArrowUp className="scroll-arrow" />
        </button>
      )}
    </div>
  );
}

export default ProjectFilmFriends;
