// Footer.js

import React from "react";
import { FaRegCopyright } from "react-icons/fa";
import { FaLinkedin, FaGithubSquare } from "react-icons/fa";
import EmailIcon from "./Email";
import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <div className="container-icons">
        <a
          href="https://www.linkedin.com/in/jenny-lu-130441198/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin className="linkedin-icon" />
        </a>
        <a
          href="https://github.com/Jennza"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithubSquare className="github-icon" />
        </a>
        <EmailIcon />
      </div>
      <div className="copyright-name">
        <FaRegCopyright className="copyright-icon" />
        <h1 className="Name">Jenny Lu</h1>
      </div>
    </div>
  );
}

export default Footer;
