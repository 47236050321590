import React from "react";
import "./ProjectTemplate.css";
import { IoIosArrowRoundForward } from "react-icons/io";

function ProjectTemplate({ title, subtitle, image, alttext, projectRef }) {
  return (
    <div>
      <a className="ProjectTemplate-image-container" href={projectRef}>
        <img className="ProjectTemplate-image" src={image} alt={alttext} />
      </a>
      <div className="ProjectTemplate-text">
        <div className="ProjectTemplate-title">{title}</div>
        <div className="ProjectTemplate-subtitle">{subtitle}</div>
        <a href={projectRef} className="row">
          <div className="ProjectTemplate-button">View Case Study</div>
          <IoIosArrowRoundForward className="arrow-casestudy" />
        </a>
      </div>
    </div>
  );
}

export default ProjectTemplate;
