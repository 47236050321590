import React from "react";
import "./ProjectEssiq.css";
import CaseStudyEssiq1 from "../Assets/Essiq/FinalDesign/Mockup-case-study.png";
import TheProcess from "../Assets/Essiq/Theprocess.png";
import Carousel from "../Carousel/CarouselUpdated";
import LadingPage from "../Assets/Essiq/FinalDesign/final1.png";
import Departments from "../Assets/Essiq/FinalDesign/final2.png";
import CaseStudy from "../Assets/Essiq/FinalDesign/final5.png";
import ContactPage from "../Assets/Essiq/FinalDesign/final3.png";
import Footer from "./Footer/Footer";
import { IoIosArrowUp } from "react-icons/io";
import { useState, useEffect } from "react";

function ProjectEssiq() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const hiddenElements = document.querySelectorAll(".hiddenElement");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.1) { // Trigger when 50% of the element is visible
          entry.target.classList.add("showElement");
          console.log("intersecting!");
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: [0.1] });
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
      if (currentPosition > 0.5 * window.innerHeight) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section className="container-Essiq">
        <h1 className="Essiq-title">ESSIQ</h1>
        <h2 className="Essiq-subtitle">Specifications for a new webpage</h2>
      </section>
      <div className="container-introduction hiddenElement">
        <p className="container-introduction-text">
          The project specifications for a new webpage were done as my final
          project for my bachelor's degree. In collaboration with my partner
          Bill Falk, we designed this project from inception to the final design
          through user research, ideation, and UX design principles.
        </p>
      </div>
      <div className="container-image-essiq hiddenElement">
        <img
          className="image-Essiq-case-study-1"
          src={CaseStudyEssiq1}
          alt="Essiq-case-study"
        />
      </div>
      <div className="section-container hiddenElement">
        <h1 className="section-title">The Context</h1>
        <p className="section-bodytext">
          The main function of the web page is to attract as well as recruit new
          employees. It is also used to inform new clients by providing relevant
          information. Clients are able to search for specific service areas to
          read about general information, cases, and worker profiles. For
          already established clients, the need slightly differs. In this case,
          the function of the web page is to find contact information as well as
          stay informed about the latest news. <br />
          <br /> However, navigating through the current website is difficult.
          Highly valuable information is hidden behind poor navigation, leaving
          users confused, uninspired, and frustrated. We were tasked with
          providing a specification of requirements as well as a prototype
          showcasing how these specifications can be implemented.
        </p>
      </div>
      <div className="section-container hiddenElement">
        <h1 className="section-title">The Process</h1>
        <div className="the-process-img">
          <img src={TheProcess} alt="theprocess" className="processimage" />
        </div>
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">The Research</div>
        <div className="section-subtitle">
          <h4>Competitor Analysis</h4>
        </div>
        <p className="section-bodytext">
          To get a better understanding of the competitor landscape, we
          conducted analyses on six companies, three direct competitors and
          three indirect. The results showed that companies who made design
          choices based on their brand identity performed better than those who
          did not and ompanies that had all the basic functions usually
          performed well. <br />
          <br /> However, companies that tried to use functions to stand out
          performed irregularly. In conclusion, being innovative and using
          functions that do not fit the mold requires morethought and effort. It
          could further improve the web page, but companies who try are at the
          risk of worsening their product.
        </p>
        <div className="section-subtitle">
          <h4>Surveys & Interviews</h4>
        </div>
        <p className="section-bodytext">
          With the goal of understand how users nagivated through the existing
          ESSIQ webpage , which features they think are important, and the
          issues they were facing, we surveyed 42 people with no prior
          experience with the webpage and 21 empoyees from ESSIQ. From these
          surveys went ahead with 16 interviews. The interviewees were asked to
          perform tasks throughout the webpage while verbally describing their
          thought process. We also asked how certain design choices changed
          their perception of the company.
        </p>
        <div className="section-subtitle">
          <h4>Key Findings & Insights</h4>
        </div>
        <p className="section-bodytext">
          Many ESSIQ employees expressed feeling that the current webpage does
          not relate to how they perceive the company. A frequent aspect was
          mentioned was the use of colors. The main color of ESSIQ is orange
          which is accompanied by the colors white, gray and black. A common
          opinion among the employees was that the web page lacked color. Many
          employees also thought it would be beneficial for ESSIQ to be
          represented in a less corporate way since it would better reflect
          their fun work place.
        </p>
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">Define</div>
        <div className="section-subtitle">
          <h4>Problem statement</h4>
        </div>
        <p className="section-bodytext">
          Users, in this case, potential employees, clients and employees need a
          webpage that showcases correct information about the company. The
          webpage needs to be easy to navigate so that the user can easily find
          the desired information. It is also crucial that the webpage
          represents the company.
        </p>
        <div className="section-subtitle">
          <h4 className="section-subtitle2">Personas & Scenarios</h4>
        </div>
        <p className="section-bodytext">
          During the project, two personas were created to act as a
          representation of potential users that would want to interact with the
          ESSIQ web page. The two individuals are based on the data gathered
          throughout the various interviews.
        </p>
        <h4 className="section-subsubtitle">Kim Hart</h4>
        <p className="section-bodytext">
          Kim Hart, a 25-year-old recent computer science Master's graduate from
          Chalmers University of Technology, is actively job-hunting, with a
          focus on consulting companies. They value the flexibility to choose
          and switch projects and are drawn to the unique salary model offered
          by such firms. Kim prefers smaller, more personal companies with a
          vibrant workplace culture and a strong sense of community. They
          appreciate web pages that convey a unique style and don't mind if
          these elements occasionally interrupt the user flow, but they don't
          consider creative web design a strict job requirement.
        </p>
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">Ideate</div>
        <div className="section-subtitle">
          <h4>Brainstorming</h4>
        </div>
        <p className="section-bodytext">
          To put structure on the brainstorming process it was decided that the
          topics should follow the categories from the specification of
          requirements. In this case the topics include; cases, landing page,
          contact page, service areas and work day. We then began writing down
          any ideas that came to mind. There were a lot of ideas therefore only
          the topic Cases is presented below.
        </p>
        <Carousel type="images" />
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">Results</div>
        <div className="section-subtitle">
          <h6>Specifications of requirements</h6>
        </div>
        <p className="section-bodytext">
          The project finally resulted in a specification of requirements that
          specifies all the details that needs to be adressed in order to
          improve the current state of the ESSIQ webpage.
        </p>
        <div className="section-subtitle">
          <h6 className="section-subtitle2">First impression</h6>
        </div>
        <p className="section-bodytext">
          The first impression of a web page is of utmost importance since it is
          the first interaction the user experiences. Based on the research, the
          lack of information about what the company actually does combined with
          a lot of bugs taints the first impression of the company. It would be
          beneficial to add an initial media that presents what the company is
          about. Either through text, image, video or a combination.
        </p>
        <div className="section-subtitle">
          <h6 className="section-subtitle2">Navigation</h6>
        </div>
        <h4 className="section-subsubtitle">Information architecture</h4>
        <p className="section-bodytext">
          Based on feedback gathered from the conducted research, users find it
          unintuitive to navigate the current web page. In order to improve this
          it would be beneficial to overhaul the current information structure.
          This means, what information is hidden behind what categories should
          be reconsidered.
        </p>
        <h4 className="section-subsubtitle">Standardized Navigation</h4>
        <p className="section-bodytext">
          The current iteration of the web page stands out from the crowd by
          deviating from the status quo in terms of navigation. This includes
          having the navigation bar off to the side, making use of horizontal
          scroll buttons, letting the user scroll between static screens rather
          than having one continuous screen and making use of embedded scrolling
          in certain boxes. While this does make the web page unique, it also
          acts as a source of confusion for users who have grown accustomed to
          standardized web-design. Standardizing some navigational aspects would
          therefore be favorable.
        </p>
        <div className="section-subtitle">
          <h6 className="section-subtitle2">Animations</h6>
        </div>
        <h4 className="section-subsubtitle">Transition Animations</h4>
        <p className="section-bodytext">
          The web page needs to favor more subtle animations that make up the
          transition between pages in order to avoid losing the users interest.
          This could either be resolved by discarding and replacing the current
          ones, or by simply speeding them up since participants noted the
          existing animations “feels like a powerpoint presentation” rather than
          a quick transition.
        </p>
        <h4 className="section-subsubtitle">Minor Animations</h4>
        <p className="section-bodytext">
          Based on research, users tended to appreciate animations like the ones
          found on the “Why ESSIQ” screen since they spark interest without
          being too distracting. The use of similar animations is therefore
          encouraged as long as they are used sparsely with purpose.
        </p>
        <div className="section-subtitle">
          <h6 className="section-subtitle2">Design</h6>
        </div>
        <h4 className="section-subsubtitle">Landing Page</h4>
        <p className="section-bodytext">
          The current landing page consists of a two way choice. However, based
          on the conducted research users would rather have a landing page which
          presents an overview of the company. Furthermore, the landing page
          compensates for a big section of the first impression. Therefore, it
          is favorable to add media that is easy to consume rather than too much
          text that is hard to process.
        </p>
        <h4 className="section-subsubtitle">Gender Diversity</h4>
        <p className="section-bodytext">
          ESSIQ's web page currently houses a good ratio between male and
          females in pictures. However, since the company focuses on gender
          equality, it is of high importance to keep a similar ratio in other
          forms of media i.e videos and when presenting workers under service
          areas.
        </p>
        <h4 className="section-subsubtitle">Colours</h4>
        <p className="section-bodytext">
          As it stands right now ESSIQ works with the colors orange, black,
          white and shades of gray. Results based on the research imply users
          desire more color to be incorporated in the web page in order for it
          to not grow bland. Thus, in order to avoid potential costs generated
          by a complete overhaul of colors, the web page should try to introduce
          some variants in hue and complementary colors to the preexisting
          theme.
        </p>
        <div className="section-subtitle">
          <h6 className="section-subtitle2">Content</h6>
        </div>
        <h4 className="section-subsubtitle">Tonality</h4>
        <p className="section-bodytext">
          Participants of the interviews noted that the current website’s
          tontality feels quite corporate and would like it if the web page
          conveyed a more playful tone. This in order to give a more accurate
          insight into how the company culture actually feels. Changing the tone
          and wording of the information presented could successfully change
          this and is therefore recommended.
        </p>
        <h4 className="section-subsubtitle">Descriptiveness</h4>
        <p className="section-bodytext">
          Based on the pre-study, the web page would benefit from the use of
          headings and subheading that better describes the overall content of
          the breadtext. The headings should not be too long and big sections of
          text should have subheadings to make it easier for users to read and
          navigate. Additionally, the heading of each page should line up with
          the ones presented in the menu, should a menu be used.
        </p>
        <h4 className="section-subsubtitle">Pictures</h4>
        <p className="section-bodytext">
          During interviews participants commonly pointed out the irrelevance of
          pictures presented on the web page. To prevent this, the pictures
          displayed should avoid having a “stock photo” feeling in favor of
          radiating a more personal feeling. Moreover, pictures used should be
          clear and patterns/filters should not compromise the visibility of the
          image.
        </p>{" "}
        <h4 className="section-subsubtitle">Duplicate Content</h4>
        <p className="section-bodytext">
          A frequent comment during the interviews was that the occurrence of
          duplicate content creates confusion. Having headers with the same
          title that contain different but similar information does not work
          well. Therefore duplicate content should be avoided altogether.
          However, it is important to note that having two ways of accessing the
          same content is not equal to duplicate content.
        </p>
        <h4 className="section-subsubtitle">Clients/Cases</h4>
        <p className="section-bodytext">
          As of today there are cases presented in service areas on the ESSIQ
          web page. However, they are difficult to access and the pictures
          presented are usually not relevant or do not provide a good angle of
          the product. During the interviews many expressed wanting to see more
          project-related pictures as well as a presentation of clients that
          ESSIQ works with. By putting clients and cases in a more visible
          location, the opportunity for users to interact with them would
          increase.
        </p>
        <h4 className="section-subsubtitle">Consistency</h4>
        <p className="section-bodytext">
          The current web page lacks consistency throughout its entirety. The
          structure of different pages needs to be cohesive in order to avoid a
          sense of sloppiness as well as unprofessionalism. For instance, some
          cases located under service areas include a profile, length of the
          project and challenges but many do not. It is therefore recommended
          that the same standard is applied throughout the web page.
        </p>
        <div className="section-subtitle">
          <h6 className="section-subtitle2">Accessibility</h6>
        </div>
        <h4 className="section-subsubtitle">Language</h4>
        <p className="section-bodytext">
          Since ESSIQ mainly operates in Sweden, the ability to change between
          two languages (Swedish and English) is a decent range and nothing that
          provokes immediate change. However, it is critical that the content
          remains identical regardless of what language the user has chosen.
          This means the language of the text and videos is the only thing that
          should be affected by a language swap.
        </p>
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">Prototypes</div>
        <p className="section-bodytext">
          The prototyping stage began with selecting the generated concepts that
          had been rated either good or outstanding during the PMI. The sketches
          of these projects were then brought into Figma, where they were
          recreated as functioning prototypes. <br />
          <br />
          When the first iteration of prototypes were completed, they were shown
          to ESSIQ with the purpose of acquiring their thoughts and feedback.
          While reactions were mostly positive, they did provide constructive
          feedback on areas that could be improved. The feedback mainly focused
          on toning down the various prototypes so that they would better align
          with the branding of the current web page, i.e white and black with
          orange as highlights and buttons.
        </p>
        <Carousel type="prototypes" />
      </div>
      <div className="section-container hiddenElement">
        <div className="section-title">The Final design</div>

        <p className="section-bodytext" style={{ textAlign: "center" }}>
          In this section the final design that was presented to Essiq is
          displayed.
        </p>
        <div className="section-subtitle">
          <h4 style={{ textAlign: "center", marginBottom: 24, fontSize: 18 }}>
            Landing Page & Areas
          </h4>
          <div className="container-Essiq-Final-Design">
            <img
              src={LadingPage}
              alt="LandingPageEssiq"
              className="EssiqFinal LandingPage"
            />
            <img
              src={Departments}
              alt="Departments"
              className="EssiqFinal Departments"
            />
            <h4 style={{ textAlign: "center", marginBottom: 32, fontSize: 18 }}>
              Cases Page
            </h4>

            <img
              src={CaseStudy}
              alt="Casestudy"
              className="EssiqFinal CaseStudy"
            />
                   <h4 style={{ textAlign: "center", marginBottom: 32,marginTop: 32, fontSize: 18 }}>
              Contact Page
            </h4>
            <img
              src={ContactPage}
              alt="ContactPage"
              className="EssiqFinal ContactPage"
            />
          </div>
        </div>
      </div>
      <Footer/>
      {showScrollToTop && (
        <button className="scroll-to-top" onClick={handleScrollTop}>
          <IoIosArrowUp className="scroll-arrow"/>
        </button>
      )}
    </div>
  );
}

export default ProjectEssiq;
